import { useCallback, useEffect, useRef, useState } from 'react'
import { useConnect } from 'redux-bundler-hook'
import { identity } from 'ramda'
import memoizeOne from 'memoize-one'

import createLogger from '~/Lib/Logging'

import { EMPTY_OBJECT } from '../constants'
import memoize from '../memoizer'
import { shallowEquals } from '../equality'
import { defer } from '../timing'

export * from './useIsLoaded'

const logger = createLogger('Utils#hooks')

export const useClientRect = () => {
  const [rect, setRect] = useState(null)
  const ref = useCallback(node => {
    if (node !== null) {
      setRect(node.getBoundingClientRect())
    }
  }, [])
  return [rect, ref]
}

export const usePrevious = value => {
  const prev = useRef(EMPTY_OBJECT)

  useEffect(() => {
    prev.current = value
  }, [value])

  return prev.current
}
usePrevious.INITIAL_STATE = Object.freeze({})

const getBubbleTaskEnqueuer = memoize((key, enqueuerConfig) => data => {
  const { flush, maxQueued, queue, scheduled, timeout } = enqueuerConfig
  // Cancel pre-existing scheduled flush
  clearTimeout(scheduled.current)
  queue.current = { ...queue.current, [key]: data }
  // If our current task fills the queue to max capacity, flush it.
  if (Object.keys(queue.current).length === maxQueued) {
    return flush()
  }
  // else schedule future auto-flush
  scheduled.current = setTimeout(flush, timeout)
  return scheduled.current
})

const getDBQPublicAPI = memoizeOne((add, flush) => ({ add, flush }))

export const useDebouncedBatchQueue = (
  receiver,
  maxQueued = 2,
  timeout = 3000
) => {
  const queue = useRef([])
  const scheduled = useRef(null)
  const flush = useCallback(() => {
    clearTimeout(scheduled.current)
    scheduled.current = null
    receiver(queue.current)
    queue.current = []
  }, [queue, scheduled, receiver])
  const add = useCallback(
    item => {
      clearTimeout(scheduled.current)
      queue.current.push(item)
      if (queue.current.length >= maxQueued) {
        flush()
        return
      }
      scheduled.current = setTimeout(flush, timeout)
    },
    [scheduled, flush]
  )

  return getDBQPublicAPI(add, flush)
}

const getTQPublicAPI = memoizeOne((pause, restart, flush, add) => ({
  pause,
  restart,
  flush,
  add,
}))
/*
 * A hook that creates an auto-flushing task queue
 * @param {Object.<string, function>} config - A mapping of queueable task types
 * and their handler functions
 * @param {number} [maxQueued=2] - The maximum capacity of the queue. When this limit is reached,
 * the queue is automaticall flushed
 * @param {numer} [timeout=3000] - The maximum wait (in ms) before auto-flushing the queue,
 * if no new tasks have been added
 */
export const useTaskQueue = config => {
  const { maxQueued = 2, timeout = 3000 } = config
  const handlers = useRef(config.handlers)
  useEffect(() => {
    Object.entries(config.handlers).forEach(([key, bubbler]) => {
      if (handlers.current[key] === bubbler) {
        return
      }
      handlers.current[key] = bubbler
    })
  }, [config.handlers])
  const queue = useRef({})
  const scheduled = useRef(null)

  const cancel = useCallback(() => {
    clearTimeout(scheduled.current)
    scheduled.current = null
  }, [scheduled])

  const flush = useCallback(() => {
    const toFlush = queue.current
    queue.current = {}
    cancel()
    Object.entries(toFlush).forEach(([key, data]) => handlers.current?.[key]?.(data))
    if (typeof config.onFlush === 'function') {
      defer(config.onFlush, defer.priorities.low)
    }
  }, [handlers, queue, scheduled])

  const restart = useCallback(() => {
    const prevQueued = Object.keys(queue.current).length
    if (!prevQueued) return null
    if (prevQueued === maxQueued) {
      return flush()
    }
    scheduled.current = setTimeout(flush, timeout)
    return scheduled.current
  }, [queue, flush])

  const enqueuerConfig = useRef({
    queue,
    flush,
    scheduled,
    maxQueued,
    timeout,
  })
  const enqueuers = useRef(
    Object.entries(config.handlers).reduce(
      (acc, [key]) => ({
        ...acc,
        [key]: getBubbleTaskEnqueuer(key, enqueuerConfig.current),
      }),
      EMPTY_OBJECT
    )
  )

  return getTQPublicAPI(cancel, restart, flush, enqueuers.current)
}

export const useControlledState = (props, propKey, transform = identity) => {
  const { [propKey]: prop } = props
  const prevProp = usePrevious(prop)
  const stateAndSetter = useState(() => transform(prop))
  const [, setState] = stateAndSetter

  useEffect(() => {
    if (prevProp === usePrevious.INITIAL_STATE) return
    const transformed = transform(prop)
    const prevTransformed = transform(prevProp)
    if (shallowEquals(transformed, prevTransformed)) return
    setState(transformed)
  }, [prop])

  return stateAndSetter
}

export const filestackURL = ({ handle, url }, me, transforms = []) => {
  const base = [url.split('/').slice(0, -1).join('/')]

  const security = me?.filestack
  if (security) {
    base.push(`security=policy:${security.policy},signature:${security.signature}`)
  }

  return [...base, ...transforms, handle].join('/')
}

export const useFilestackURL = () => {
  const { me } = useConnect('selectMe')
  return useCallback((attachment, actions = []) => (
    filestackURL(attachment, me, actions)
  ), [me?.filestack])
}
