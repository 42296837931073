import config from '~/App/config'
import { getAsyncActionIdentifiers } from '~/Lib/createEntityBundle'
import createLogger from '~/Lib/Logging'
import { parseApiErrors } from '~/Lib/Utils'

const logger = createLogger('Device/bundle#provisioning')

const deprovisionActions = getAsyncActionIdentifiers('deprovision', 'device')

const initialState = {
  isLoading: false,
}

const provisioningBundle = {
  name: 'provisioning',
  reducer: (state = initialState, action = {}) => {
    switch (action.type) {
      case deprovisionActions.types.start:
        return { ...state, isLoading: true }
      case deprovisionActions.types.succeed:
        return { ...state, isLoading: false }
      case deprovisionActions.types.fail:
        return { ...state, isLoading: false }
      default:
        return state
    }
  },
  doDeprovisionDevice: sn => async ({ apiFetch, dispatch }) => {
    logger.debug('deprovisioning...', sn)
    const url = new URL('/provisioner_api/self_provisioning/deprovision/', config.API_URL).href
    dispatch({ type: deprovisionActions.types.start })
    return apiFetch(
      url,
      // eslint-disable-next-line babel/camelcase
      { aroya_serial: sn },
      {
        headers: { Authorization: `Bearer ${config.PROVISIONER_API_TOKEN}` },
        method: 'POST',
      },
    ).then(response => {
      const msg = typeof response === 'object'
        ? JSON.stringify(response)
        : response
      dispatch({ type: deprovisionActions.types.succeed, payload: msg })
      dispatch({ actionCreator: 'doAddSnackbarMessage', args: [msg ?? `Deprovisioning ${sn}`] })
      return response ?? true
    }).catch(e => {
      const error = parseApiErrors(e)
      dispatch({ type: deprovisionActions.types.fail, error })
      dispatch({ actionCreator: 'doAddSnackbarMessage', args: [error] })
    })
  },
}

export default provisioningBundle
