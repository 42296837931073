import { useEffect, useRef, useState } from 'react'

import createLogger from '~/Lib/Logging'

const logger = createLogger('useIsLoaded')

export const useIsLoaded = (isLoading, timeout = 1000, identifier = 'anon') => {
  const initialLoadingRef = useRef(isLoading)
  const loadingRef = useRef(isLoading)
  // set loaded to null if loading hasn't started at first render
  const [loaded, setLoaded] = useState(isLoading ? false : null)

  // Don't get stuck in unloaded state
  // after timeout we set loaded true in case loading finished before first render
  useEffect(() => {
    let timeoutID
    let failsafeTimeoutID
    if (!loaded) {
      if (process.env.NODE_ENV === 'test') {
        // deepcode ignore PromiseNotCaughtGeneral: it can't throw an exception
        Promise.resolve().then(() => setLoaded(true))
        return () => null
      }
      logger.debug(identifier, '=> loading initially', String(isLoading))
      logger.debug(identifier, '=> loaded initially', String(loaded))
      failsafeTimeoutID = setTimeout(() => {
        logger.debug(identifier, '=> failsafe timeout fired')
        failsafeTimeoutID = null
        if (!loadingRef.current) {
          return
        }
        logger.debug(identifier, '=> isLoading still true at timeout * 3, forcing loaded to true', {
          initiallyLoading: initialLoadingRef.current,
          loading: loadingRef.current
        })
        setLoaded(true)
      }, timeout * 3)
      timeoutID = setTimeout(() => {
        logger.debug(identifier, '=> timeout fired')
        timeoutID = null
        setLoaded(old => {
          if (!old && !loadingRef.current) {
            logger.debug(identifier, '=> setting loaded to true due to timeout')
            clearTimeout(failsafeTimeoutID)
            failsafeTimeoutID = null
            return true
          }
          return old
        })
      }, timeout)
    }
    return () => {
      logger.debug(identifier, '=> clearing timeout')
      if (timeoutID) clearTimeout(timeoutID)
      if (failsafeTimeoutID) clearTimeout(failsafeTimeoutID)
    }
  }, [loaded])

  useEffect(() => {
    loadingRef.current = isLoading
    // if isLoading was false at mount, but it is true now
    // set loaded to false
    if (loaded === null && isLoading) {
      logger.debug(identifier, '=> loading has started; setting loaded to false')
      setLoaded(false)
    }
    // isLoading was true in the past (loaded is false)
    // and isLoading is now false
    // set loaded to true
    if (loaded === false && !isLoading) {
      logger.debug(identifier, '=> loading has ended; setting loaded to true')
      setLoaded(true)
    }
  }, [loaded, isLoading])

  return Boolean(loaded)
}
