import React, { useMemo } from 'react'

import {
  dropLastWhile,
  partition,
  prop,
  uniqBy,
} from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Grid, Paper, Typography } from '@material-ui/core'

import { hours } from 'milliseconds'

import createLogger from '~/Lib/Logging'
import { EMPTY_ARRAY, formatDateTime } from '~/Lib/Utils'
import Chart from '~/UI/Shared/Chart'

const displayName = 'device/chart'
const logger = createLogger(displayName)

const ONE_DAY = hours(24)

const dropTrailingNulls = dropLastWhile(({ y }) => y === null)

const DeviceChartsComponent = ({ deviceId }) => {
  const {
    dataTypes,
    deviceChart,
    deviceChartIsLoading,
    deviceChartShowAxes,
    doDeviceChartSetParams,
  } = useConnect(
    'selectDataTypes',
    'selectDeviceChart',
    'selectDeviceChartIsLoading',
    'selectDeviceChartShowAxes',
    'doDeviceChartSetParams',
  )

  const finalChartData = useMemo(() => (
    Array.isArray(deviceChart)
      ? deviceChart.map(chart => {
        if (!chart.dataTypes.every(dt => dataTypes[dt]?.category === 'OTHER')) {
          return chart
        }
        const { data, graphs } = chart
        const now = new Date()
        const hasScatter = new Set()
        return {
          ...chart,
          data: Object.fromEntries(Object.entries(data).flatMap(([key, values]) => {
            const [scatter, line] = partition(datum => now - new Date(datum.x) > ONE_DAY, values)
            if (scatter.length) {
              hasScatter.add(key)
            }
            return scatter.length ? [
              [`${key}-scatter`, dropTrailingNulls(scatter)],
              [key, dropTrailingNulls(values)]
            ] : [[key, line]]
          })),
          graphs: graphs.flatMap(graph => {
            if (hasScatter.has(graph.id)) {
              return [
                { ...graph, id: `${graph.id}-scatter`, type: 'scatter' },
                graph,
              ]
            }
            return graph
          })
        }
      })
      : deviceChart
  ), [deviceChart])

  const deviceInfoFields = [
    { key: 'gateway_sn', name: 'Gateway' },
    { key: 'model_name', name: 'Model' },
    { key: 'pv_ver', name: 'PV Ver.' },
    { key: 'room_name', name: 'Room' },
    { key: 'zone_name', name: 'Zone' },
    { key: 'version', name: 'FW Ver.' },
    { key: 'hw_ver', name: 'HW Ver.' },
    { key: 'chip_id', name: 'HW Id' },
    { key: 'node_address', name: 'Node Addr.' },
    { key: 'deep_sleep', name: 'Deep Sleep', format: value => (value ? formatDateTime(value) : 'No') },
    { key: 'leased', name: 'Leased', format: leased => (leased ? 'Yes' : 'No') },
    { key: 'last_communication', name: 'Last Communication', format: formatDateTime },
    { key: 'created_on', name: 'Created On', format: formatDateTime },
    { key: 'mac_address', name: 'Mac Addr.' },
    { key: 'vpn_ip', name: 'Vpn IP.' },
  ]

  const uniqueByUnit = uniqBy(prop('unit'))
  const graphCount = deviceChart?.length ? (
    Math.max(...deviceChart.map(chart => uniqueByUnit(chart.graphs ?? EMPTY_ARRAY).length))
  ) : 0

  return (
    <Grid container direction="column" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: '8px', paddingRight: '8px' }}>
      <svg height={0} width={0}>
        <defs>
          <filter id="lineShadow" width="200%" height="200%">
            <feOffset result="offOut" in="SourceGraphic" dx="0.2" dy="0.2" />
            <feColorMatrix
              result="matrixOut"
              in="offOut"
              type="matrix"
              values="0.15 0 0 0 0 0 0.15 0 0 0 0 0 0.15 0 0 0 0 0 1 0"
            />
            <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="0.2" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
          </filter>
        </defs>
      </svg>
      <Grid container direction="column" style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden', paddingRight: '5px' }}>
        { deviceId && finalChartData?.length ? (
          <Grid container spacing={2} direction="column">
            {finalChartData.map(chart => (
              <Grid item key={chart.device.id}>
                <Paper style={{ height: '500px', padding: '10px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Grid container spacing={1}>
                      <Grid item>
                        { chart.device.device_name === chart.device.serial_number ? (
                          <Typography variant="h5" color="primary">
                            { chart.device.device_name }
                          </Typography>
                        ) : (
                          <>
                            <Typography color="primary">
                              { chart.device.device_name }
                            </Typography>
                            { chart.device.serial_number }
                          </>
                        )}
                      </Grid>
                      { deviceInfoFields.map(field => {
                        const value = chart.device[field.key]
                        const formatted = field.format ? field.format(value) : value
                        if (formatted === null || formatted === undefined || formatted === '') return null
                        return (
                          <Grid item key={`${chart.device.id}-${field.key}`}>
                            <Typography color="textSecondary">
                              { field.name }
                            </Typography>
                            { formatted }
                          </Grid>
                        )
                      })}
                    </Grid>
                    <div style={{ flex: '1 1 auto', height: '100%' }}>
                      <Chart
                        chartData={chart}
                        doSetChartParams={doDeviceChartSetParams}
                        graphCount={graphCount}
                        loading={deviceChartIsLoading}
                        showYAxis={deviceChartShowAxes}
                        width="lg"
                      />
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        ) : (
          <div style={{ position: 'relative', width: '100%', marginTop: '50px' }}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Typography variant="h5">
                No data
              </Typography>
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  )
}

export default React.memo(DeviceChartsComponent)
