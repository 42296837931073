import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import { normalize } from 'normalizr'
import { partition } from 'ramda'
import reduceReducers from 'reduce-reducers'

import { ENTITIES_RECEIVED } from '~/Lib/createEntityBundle'
import { sortNestedArrayBy } from '~/Lib/Data'
import { textSort } from '~/Lib/Utils'
import { Device } from '~/Store/Schemas'
import { updateUnlessNullorUndefined } from '~/Device/utils'

export const DEVICE_ID_UPDATED = 'DEVICE_ID_UPDATED'
const DEVICE_LIST_MODELS_FETCH_STARTED = 'DEVICE_LIST_MODELS_FETCH_STARTED'
const DEVICE_LIST_MODELS_FETCH_FAILED = 'DEVICE_LIST_MODELS_FETCH_FAILED'
const DEVICE_LIST_MODELS_FETCH_FINISHED = 'DEVICE_LIST_MODELS_FETCH_FINISHED'
const DEVICE_DATA_EXPORT_FAILED = 'DEVICE_DATA_EXPORT_FAILED'
const DEVICE_LIST_SET_PAGE = 'DEVICE_LIST_SET_PAGE'
const DEVICE_LIST_SET_SORT = 'DEVICE_LIST_SET_SORT'

const additionalState = {
  currentPage: 1,
  currentSort: 'gateway_sn',
  deviceId: undefined,
  modelChoices: [],
}

const deviceListBundle = createAsyncResourceBundle({
  name: 'deviceList',
  actionBaseType: 'DEVICE_LIST',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, getState, dispatch, store }) => {
    const { deviceList } = getState()
    const { currentPage, currentSort } = deviceList

    const model = store.selectDeviceListCurrentModel()
    const room = store.selectDeviceListCurrentRoom()
    const facility = store.selectDeviceListCurrentFacility()
    const organization = store.selectDeviceListCurrentOrganization()
    const search = store.selectDeviceListSearch()
    const status = store.selectDeviceListOnlineFilter()
    const retired = store.selectDeviceListRetiredFilter()
    const wirepas = store.selectDeviceListWirepasFilter()

    /* eslint-disable babel/camelcase */
    return apiFetch('/devices/', {
      aroya: true,
      facility_id: facility || undefined,
      model: model || undefined,
      online: status,
      organization_id: organization || undefined,
      ordering: currentSort,
      page: currentPage,
      retired,
      wirepas: wirepas || undefined,
      room: room || undefined,
      search,
    }).then(payload => {
      const { entities, result } = normalize(payload.results, [Device])
      dispatch({ type: ENTITIES_RECEIVED, payload: entities })
      return { ...payload, results: result }
    })
    /* eslint-enable babel/camelcase */
  }
})

export default {
  ...deviceListBundle,
  reducer: reduceReducers(deviceListBundle.reducer, (state, action) => {
    switch (action.type) {
      case DEVICE_ID_UPDATED:
        return { ...state, deviceId: action.payload }
      case DEVICE_LIST_MODELS_FETCH_FINISHED:
        return { ...state, modelChoices: action.payload }
      case DEVICE_LIST_SET_PAGE:
        return { ...state, currentPage: action.payload }
      case DEVICE_LIST_SET_SORT:
        return { ...state, currentPage: 1, currentSort: action.payload }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state }
        }
        return state
    }
  }),
  doDeviceListSetFacility: facId => async ({ store }) => {
    const queryObj = store.selectQueryObject()
    if (facId === store.selectDeviceListCurrentFacility()) return
    store.doDeviceListSetPage(1)
    store.doDeviceEventsSetPage(1)
    if (facId) {
      if (store.selectFacilities()?.[facId]?.payloadType !== 'entity') {
        await store.doFacilityFetch(facId)
      }
    }
    store.doDeviceListSetRoom()
    const payload = updateUnlessNullorUndefined(queryObj, 'facility', facId)
    store.doUpdateQuery(payload)
    store.doMarkDeviceListAsOutdated()
  },
  doDeviceListSetModel: modelKey => ({ store }) => {
    const queryObj = store.selectQueryObject()
    if (modelKey === store.selectDeviceListCurrentModel()) return
    store.doDeviceListSetPage(1)
    store.doDeviceEventsSetPage(1)
    const payload = updateUnlessNullorUndefined(queryObj, 'model', modelKey)
    store.doUpdateQuery(payload)
    store.doMarkDeviceListAsOutdated()
  },
  doDeviceListSetOnlineFilter: online => ({ store }) => {
    const queryObj = store.selectQueryObject()
    if (online === store.selectDeviceListOnlineFilter()) return
    store.doDeviceListSetPage(1)
    store.doDeviceEventsSetPage(1)
    const payload = updateUnlessNullorUndefined(queryObj, 'status', online)
    store.doUpdateQuery(payload)
    store.doMarkDeviceListAsOutdated()
  },
  doDeviceListSetRetiredFilter: retired => ({ store }) => {
    const queryObj = store.selectQueryObject()
    if (retired === store.selectDeviceListRetiredFilter()) return
    store.doDeviceListSetPage(1)
    store.doDeviceEventsSetPage(1)
    const payload = updateUnlessNullorUndefined(queryObj, 'retired', retired)
    store.doUpdateQuery(payload)
    store.doMarkDeviceListAsOutdated()
  },
  doDeviceListSetWirepasFilter: wirepas => ({ store }) => {
    const queryObj = store.selectQueryObject()
    if (wirepas === store.selectDeviceListWirepasFilter()) return
    store.doDeviceListSetPage(1)
    store.doDeviceEventsSetPage(1)
    const payload = updateUnlessNullorUndefined(queryObj, 'wirepas', wirepas)
    store.doUpdateQuery(payload)
    store.doMarkDeviceListAsOutdated()
  },
  doDeviceListSetOrganization: orgId => ({ store }) => {
    const queryObj = store.selectQueryObject()
    if (orgId === store.selectDeviceListCurrentOrganization()) return
    store.doDeviceListSetPage(1)
    store.doDeviceEventsSetPage(1)
    if (orgId) {
      store.doDeviceListSetFacility()
    }
    const payload = updateUnlessNullorUndefined(queryObj, 'organization', orgId)
    store.doUpdateQuery(payload)
    store.doMarkDeviceListAsOutdated()
  },
  doDeviceListSetRoom: roomId => ({ store }) => {
    const queryObj = store.selectQueryObject()
    if (roomId === store.selectDeviceListCurrentRoom()) return
    store.doDeviceListSetPage(1)
    store.doDeviceEventsSetPage(1)
    const payload = updateUnlessNullorUndefined(queryObj, 'room', roomId)
    store.doUpdateQuery(payload)
    store.doMarkDeviceListAsOutdated()
  },
  doDeviceListSetSearch: search => ({ store }) => {
    const queryObj = store.selectQueryObject()
    if (search === store.selectDeviceListSearch()) return
    store.doDeviceListSetPage(1)
    store.doDeviceEventsSetPage(1)
    const payload = updateUnlessNullorUndefined(queryObj, 'search', search)
    store.doUpdateQuery(payload)
    store.doMarkDeviceListAsOutdated()
  },
  doDeviceListSetPage: page => ({ dispatch, store }) => {
    dispatch({ type: DEVICE_LIST_SET_PAGE, payload: page })
    store.doMarkDeviceListAsOutdated()
  },
  doDeviceListSetSort: sort => ({ dispatch, store }) => {
    if (sort === store.selectDeviceListSort()) return
    dispatch({ type: DEVICE_LIST_SET_SORT, payload: sort })
    store.doMarkDeviceListAsOutdated()
  },
  doFetchDeviceListModels: () => async ({ apiFetch, dispatch }) => {
    dispatch({ type: DEVICE_LIST_MODELS_FETCH_STARTED })
    try {
      const response = await apiFetch('/devices/models/')
      dispatch({ type: DEVICE_LIST_MODELS_FETCH_FINISHED, payload: response })
    } catch (error) {
      dispatch({ type: DEVICE_LIST_MODELS_FETCH_FAILED, payload: error })
    }
  },
  doSnoozeDevice: payload => async ({ apiFetch, dispatch, store }) => {
    try {
      const { device, duration } = payload
      const response = await apiFetch(`/devices/${device.id}/snooze/`, { duration }, { method: 'POST' })
      store.doAddSnackbarMessage(`Successfully ${duration < 0 ? 'unmuted' : 'muted'} notifications for ${device.serialNumber}`)

      const { entities, result } = normalize(response, Device)
      dispatch({ type: ENTITIES_RECEIVED, payload: entities })
      return result
    } catch (error) {
      return error
    }
  },
  doClearDeviceFilters: () => ({ store }) => {
    store.doUpdateQuery({})
    store.doDeviceListSetPage(1)
    store.doDeviceEventsSetPage(1)
    store.doMarkDeviceListAsOutdated()
    store.doMarkDeviceChartAsOutdated()
    store.doMarkDeviceEventsAsOutdated()
  },
  doDeviceExportData: deviceId => async ({ dispatch, apiFetch, store }) => {
    const user = store.selectMe()
    try {
      const success = await apiFetch('/export/device/', { deviceId }, { method: 'POST' })
      if (success) {
        store.doAddSnackbarMessage(`An email will be sent to ${user.email} shortly.`)
      } else {
        store.doAddSnackbarMessage('Failed to export device data.')
      }
    } catch (error) {
      store.doAddSnackbarMessage('Failed to export device data.')
      dispatch({ type: DEVICE_DATA_EXPORT_FAILED, payload: error })
    }
  },
  reactDeviceListFetch: createSelector(
    'selectAuth',
    'selectDeviceListShouldUpdate',
    'selectRouteInfo',
    ({ authenticated }, shouldUpdate, { url }) => {
      if (authenticated && shouldUpdate && url.startsWith('/devices')) {
        return { actionCreator: 'doFetchDeviceList' }
      }
      return undefined
    }
  ),
  selectDeviceList: createSelector(
    'selectDevices',
    'selectDeviceListRaw',
    (devices, { data }) => {
      if (!data) {
        return []
      }
      const fullDevices = data.results
        .map(deviceId => devices[deviceId])
        .filter(device => device !== undefined)
      return { ...data, results: fullDevices }
    }
  ),
  selectCurrentDeviceList: createSelector(
    'selectDeviceList',
    'selectDeviceListSearch',
    'selectDeviceListSort',
    ({ results }, deviceListSearch, deviceListSort) => {
      if (!results) return []
      const deviceIsGateway = deviceListSort === 'gateway_sn'
      const deviceIsSink = deviceListSort === 'serial_number'
      const isGatewayOrSerialNumber = (deviceIsGateway || deviceIsSink)

      // If sorting by gateway or serial number use custom sort
      if (isGatewayOrSerialNumber) {
        // Partition conditions
        const partitionedDeviceList = partition(device => {
          const serialNumberMatch = (device.serialNumber.endsWith(deviceListSearch))
          const gatewaySerialNumberMatch = (device.gatewaySn.endsWith(deviceListSearch))

          return serialNumberMatch || (gatewaySerialNumberMatch && device.modelKey === 'sink')
          // results are the original deviceList.results
        }, results)

        const firstPartitionSort = (a, b) => {
          const aGateway = a.gatewaySn || a.serialNumber
          const bGateway = b.gatewaySn || b.serialNumber
          // Sort by gateway serial number first
          if (aGateway !== bGateway) return textSort(aGateway, bGateway)
          // Then if gateway and its sink, sort gateway first
          return textSort(a.modelKey, b.modelKey)
        }

        partitionedDeviceList[0].sort(firstPartitionSort)

        const prioritizedDeviceList = partitionedDeviceList[0].length
          ? [...partitionedDeviceList[0], ...partitionedDeviceList[1]]
          : partitionedDeviceList[1]

        return prioritizedDeviceList
      }
      // If sorting by any other field return original deviceList.results
      return results
    }
  ),
  selectDeviceListCurrentFacility: createSelector(
    'selectQueryObject',
    ({ facility }) => (facility ? parseInt(facility, 10) : undefined)
  ),
  selectDeviceListCurrentModel: createSelector(
    'selectQueryObject',
    ({ model }) => model
  ),
  selectDeviceListCurrentOrganization: createSelector(
    'selectQueryObject',
    ({ organization }) => (organization ? parseInt(organization, 10) : undefined)
  ),
  selectDeviceListCurrentRoom: createSelector(
    'selectQueryObject',
    ({ room }) => (room ? parseInt(room, 10) : undefined)
  ),
  selectDeviceListOnlineFilter: createSelector(
    'selectQueryObject',
    ({ status }) => {
      if (status === 'true') return true
      if (status === 'false') return false
      return undefined
    }
  ),
  selectDeviceListRetiredFilter: createSelector(
    'selectQueryObject',
    ({ retired }) => {
      if (retired === 'true') return true
      if (retired === 'false') return false
      return undefined
    }
  ),
  selectDeviceListWirepasFilter: createSelector(
    'selectQueryObject',
    ({ wirepas }) => {
      if (wirepas === 'device_5') return 'device_5'
      if (wirepas === 'gateway_5') return 'gateway_5'
      if (wirepas === 'gateway_4') return 'gateway_4'
      if (wirepas === 'device_4') return 'device_4'
      return undefined
    }
  ),
  selectDeviceListSearch: createSelector(
    'selectQueryObject',
    ({ search }) => search
  ),
  selectDeviceListSort: createSelector(
    'selectDeviceListRaw',
    ({ currentSort }) => currentSort
  ),
  selectDeviceListFacilityChoices: createSelector(
    'selectCurrentFacilityList',
    'selectDeviceListCurrentOrganization',
    (currentFacilityList, currentOrganization) => {
      const choices = currentFacilityList
        .filter(f => f.active)
        .filter(fac => !currentOrganization || fac.organization === currentOrganization)
        .map(fac => ({ label: fac.name, value: fac.id }))

      return [
        { label: 'All Facilities', value: null },
        ...choices
      ]
    }
  ),
  selectDeviceListModelChoices: createSelector(
    'selectDeviceListRaw',
    ({ modelChoices }) => {
      const choices = modelChoices.map(model => ({ label: model.name, value: model.key }))
      return [
        { label: 'All Models', value: null },
        ...choices
      ]
    }
  ),
  selectDeviceListOrganizationChoices: createSelector(
    'selectOrganizations',
    organizations => {
      const choices = organizations ? Object.values(organizations)
        .filter(org => org.active)
        .map(org => ({ label: org.name, value: org.id })) : []

      const sortedChoices = sortNestedArrayBy('label', choices)
      sortedChoices.unshift({ label: 'All Organizations', value: null })

      return sortedChoices
    }
  ),
  persistActions: [...deviceListBundle.persistActions, DEVICE_ID_UPDATED],
}
