import React, { useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import SearchIcon from '@material-ui/icons/Search'

import {
  Grid,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  Typography,
} from '@material-ui/core'

import { useIsLoaded } from '~/Lib/Utils'
import DatePicker from '~/UI/Shared/Form/DatePicker'
import SearchField from '~/UI/Shared/Form/SearchField'
import Icon from '~/UI/Shared/Icon'
import MuiTitle from '~/UI/Shared/MuiTitle'

import { DeviceIcons } from './utils'
import DeviceCharts from './charts'
import DeviceEvents from './events'
import DeviceCommands from './commands'
import OSErrors from './oserrors'
import DeviceFilters from './filters'
import Paginator from './paginator'

const DeviceComponent = props => {
  const { leftCol, centerCol, rightCol } = props

  const {
    currentDeviceList,
    widths,
    device,
    deviceList,
    deviceListSearch,
    deviceChartStartDate,
    deviceChartEndDate,
    deviceListIsLoading,
    queryString,
    doDeviceChartSetParams,
    doDeviceListSetPage,
    doDeviceListSetSearch,
    doFacilityFetch,
    doFetchDeviceEventsKeys,
    doFetchDeviceListModels,
    doMarkDeviceChartAsOutdated,
    doMarkDeviceEventsAsOutdated,
    doMarkDeviceListAsOutdated,
    doUpdateUrl,
  } = useConnect(
    'selectCurrentDeviceList',
    'selectWidths',
    'selectDevice',
    'selectDeviceList',
    'selectDeviceEvents',
    'selectDeviceListSearch',
    'selectDeviceChartStartDate',
    'selectDeviceChartEndDate',
    'selectDeviceListIsLoading',
    'selectQueryString',
    'doDeviceChartSetParams',
    'doDeviceListSetPage',
    'doDeviceListSetSearch',
    'doFacilityFetch',
    'doFetchDeviceEventsKeys',
    'doFetchDeviceListModels',
    'doMarkDeviceChartAsOutdated',
    'doMarkDeviceEventsAsOutdated',
    'doMarkDeviceListAsOutdated',
    'doFetchUpdatesForDeviceQuery',
    'doUpdateUrl',
  )
  const [exportButtonDisabled, setExportButtonDisabled] = useState(false)
  const loaded = useIsLoaded(deviceListIsLoading)

  useEffect(() => {
    doMarkDeviceListAsOutdated()
    doMarkDeviceChartAsOutdated()
    doMarkDeviceEventsAsOutdated()
    doFetchDeviceListModels()
  }, [])

  // Auto selects fist search result when it's an exact match for the search term
  useEffect(() => {
    if (deviceListIsLoading) return

    const deviceIsInList = Boolean(currentDeviceList.find(d => d.id === device.id))
    const { 0: firstDevice = {} } = currentDeviceList
    const { id, gatewaySn, serialNumber } = firstDevice
    const deviceIdMatch = id === device.id

    if (!currentDeviceList.length) {
      if (device.id) {
        doUpdateUrl({ pathname: '/devices', query: queryString })
      }
      return
    }

    // If device id matches first device id in the list or no search term available or device is already in list, exit useEffect
    if (deviceIdMatch || !deviceListSearch || deviceIsInList) return

    const searchMatch = serialNumber === deviceListSearch || gatewaySn === deviceListSearch

    if (searchMatch) {
      doUpdateUrl({ pathname: `/devices/${id}`, query: queryString })
      doMarkDeviceEventsAsOutdated()
    }
  }, [currentDeviceList, device.id, deviceListSearch, deviceListIsLoading])

  const title = device.id ? device.serialNumber : 'Devices'
  const { window } = widths
  const dynamicTextSize = window < 1600 ? '8px' : '10px'
  const dynamicSerialNumberSize = window < 1600 ? 'body2' : 'subtitle2'

  return (
    <>
      <Grid container direction="column" style={{ height: 'calc(100vh - 78px)' }}>
        <Grid container spacing={2}>
          <Grid item style={{ flexGrow: 1 }}>
            <MuiTitle title={title} />
          </Grid>
          <Grid item style={{ flexBasis: '225px', marginTop: '-30px' }}>
            <DatePicker
              datetime
              fullWidth
              label="From"
              name="start"
              value={deviceChartStartDate}
              onChange={doDeviceChartSetParams}
            />
          </Grid>
          <Grid item style={{ flexBasis: '225px', marginTop: '-30px' }}>
            <DatePicker
              datetime
              fullWidth
              label="To"
              name="end"
              value={deviceChartEndDate}
              onChange={doDeviceChartSetParams}
            />
          </Grid>
          <Grid item style={{ flexBasis: '300px', marginTop: '-30px' }}>
            <SearchField
              fullWidth
              label="Search Devices"
              value={deviceListSearch}
              onEmpty={() => {
                doDeviceListSetSearch(null)
              }}
              onChange={({ searchTerms }) => {
                if (!searchTerms.length || searchTerms.length >= 3) {
                  doDeviceListSetSearch(searchTerms.trim() || null)
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon icon={SearchIcon} />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        <DeviceFilters
          exportButtonDisabled={exportButtonDisabled}
          setExportButtonDisabled={setExportButtonDisabled}
          {...props}
        />
        <Grid container spacing={2} style={{ flex: '1' }}>
          <Grid item xs={leftCol} style={{ position: 'relative' }}>
            <Grid container direction="column" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
              {currentDeviceList?.length ? (
                <>
                  <Grid container style={{ flex: 1, overflowY: 'auto' }}>
                    <List style={{ width: '100%' }}>
                      {!deviceListIsLoading && loaded ? currentDeviceList.map(dev => (
                        <ListItem
                          button
                          href={`/devices/${dev.id}${queryString ? `?${queryString}` : ''}`}
                          selected={device.id === dev.id}
                          key={dev.id}
                          component="a"
                          onClick={() => {
                            if (dev.facility) doFacilityFetch(dev.facility)
                            setExportButtonDisabled(false)
                            doMarkDeviceEventsAsOutdated()
                            doFetchDeviceEventsKeys(dev.id)
                          }}
                        >
                          <Grid container style={{ marginLeft: '-10px' }} justify="space-between" wrap="nowrap">
                            <Grid container direction="column">
                              <Typography variant="body2" color="primary" style={{ fontSize: dynamicTextSize }}>
                                {dev.connected ? `${dev.modelName.toUpperCase()} - ${dev.connected}` : `${dev.modelName.toUpperCase()}`}
                              </Typography>
                              <Typography variant={dynamicSerialNumberSize} style={{ color: '#fff' }}>
                                {dev.serialNumber}
                              </Typography>
                              <Typography variant="body2" style={{ fontSize: dynamicTextSize }}>
                                {dev.facilityName}
                              </Typography>
                              {dev.orgName && (
                                <Typography variant="body2" style={{ fontSize: dynamicTextSize }}>
                                  {dev.orgName}
                                </Typography>
                              )}
                            </Grid>
                            <Grid container justify="flex-end" style={{ marginRight: '-20px' }} wrap="nowrap" alignItems="center">
                              <DeviceIcons dense device={dev} dynamicTextSize={dynamicTextSize} />
                            </Grid>

                          </Grid>
                        </ListItem>
                      )) : (
                        <div style={{ maxWidth: '90%', paddingTop: '3rem' }}>
                          <LinearProgress />
                        </div>
                      )}
                    </List>
                  </Grid>
                  <Paginator
                    data={deviceList}
                    isLoading={deviceListIsLoading}
                    onChange={doDeviceListSetPage}
                  />
                </>
              ) : (
                <div style={{ position: 'relative', width: '100%', marginTop: '50px' }}>
                  <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Typography variant="h5">
                      No devices
                    </Typography>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid item xs={centerCol} style={{ position: 'relative' }}>
            <DeviceCharts deviceId={device.id} />
          </Grid>
          <Grid item xs={rightCol} style={{ position: 'relative' }}>
            <DeviceEvents deviceId={device.id} />
          </Grid>

        </Grid>

      </Grid>
      <div style={{ position: 'relative', display: 'flex' }}>
        <DeviceCommands deviceNode={device.nodeAddress} deviceNetwork={device.orgNetwork} />
        <OSErrors deviceId={device.id} />
      </div>
    </>
  )
}

export default () => (
  <DeviceComponent leftCol={2} centerCol={7} rightCol={3} />
)
